<template>
  <b-card
    title=""
    class="mb-2"
    ref="edit-job"
    id="edit-job"
    hide-footer
  >
    <div class="job_editor">

      <b-form class="mt-8">

        <b-form-group id="input-group-job_id" label="ID" label-for="input-job_id">
          <b-form-input
            id="input-job_id"
            v-model="local_job_record.id"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-num_processed" :label="$t('JOB.NUM_PROCESSED')" label-for="input-num_processed">
          <b-form-input
            id="input-num_processed"
            v-model="local_job_record.num_processed"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-num_fail" :label="$t('JOB.NUM_FAIL')" label-for="input-num_fail">
          <b-form-input
            id="input-num_fail"
            v-model="local_job_record.num_fail"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>


        <b-form-group id="input-group-num_success" :label="$t('JOB.NUM_SUCCESS')" label-for="input-num_success">
          <b-form-input
            id="input-num_success"
            v-model="local_job_record.num_success"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>


      </b-form>
    </div>
  </b-card>
</template>

<style lang="scss">


</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import ShopOrderItemTable from '@/view/pages/ml/shop_order/ShopOrderItemTable.vue';

import QuickSearchMemberWidget from '@/view/pages/ml/search/QuickSearchMemberWidget.vue';

export default {
  name: 'education-import-job-editor',
  components: {
    Confirm,
    QuickSearchMemberWidget,
    ShopOrderItemTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {

    if (this.job_record) {
      this.local_job_record = { ...this.job_record }
    }
  },
  data() {
    return {
      local_job_record: {},

    };
  },
  props: ['job_record'],
  watch: {

    job_record: {
      deep: true,
      handler(val) {
        this.local_job_record = { ...this.job_record }
      }
    }

  },
  methods: {

  }
};
</script>
