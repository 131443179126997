<template>
  <b-card
    title=""
    class="mb-2"
    ref="edit-education"
    id="edit-education"
    hide-footer

  >
  
    <div class="education_editor">

      <b-form @submit.prevent="updateEducation" class="mt-8">

        <b-form-group id="input-group-education_id" label="ID" label-for="input-education_id">
          <b-form-input
            id="input-education_id"
            v-model="local_education.id"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-faculty_id" :label="$t('PAGES.EDUCATIONS.FACULTY')" label-for="input-faculty_id">
          <FacultyList
            :faculty_id="local_education.faculty_id"
            v-on:update:faculty_id="updated_faculty_id"
          />
        </b-form-group>

        <b-form-group id="input-group-company_id" :label="$t('PAGES.EDUCATIONS.SECTION_COMPANY')" label-for="input-company_id">
          <CompanyList
            :company_id="local_education.company_id"
            v-on:update:company_id="updated_company_id"
          />
        </b-form-group>

        <b-form-group
          id="type"
          :label="$t('PAGES.EDUCATIONS.TYPE')"
          label-for="type"
          class="mt-8"
          :state="validType"
        >
          <b-form-select v-model="local_education.type" :options="typeOptions"></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-code" :label="$t('PAGES.EDUCATIONS.CODE')" label-for="input-code">
          <b-form-input
            id="input-code"
            v-model="local_education.code"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-program_code" :label="$t('PAGES.EDUCATIONS.PROGRAM_CODE')" label-for="input-program_code">
          <b-form-input
            id="input-program_code"
            v-model="local_education.program_code"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-name" :label="$t('PAGES.EDUCATIONS.NAME')" label-for="input-name">
          <b-form-input
            id="input-name"
            v-model="local_education.name"
            type="text"
          ></b-form-input>
        </b-form-group>


        <div v-if="(errors.length > 0)" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">

              <ul>
                <li v-for="(error) in errors">
                  <div v-if="error === 'company'">{{$t('PAGES.EDUCATIONS.ERROR_COMPANY')}}</div>
                  <div v-if="error === 'faculty'">{{$t('PAGES.EDUCATIONS.ERROR_FACULTY')}}</div>
                  <div v-if="error === 'type'">{{$t('PAGES.EDUCATIONS.ERROR_TYPE')}}</div>
                  <div v-if="error === 'code'">{{$t('PAGES.EDUCATIONS.ERROR_CODE')}}</div>
                  <div v-if="error === 'name'">{{$t('PAGES.EDUCATIONS.ERROR_NAME')}}</div>
                </li>
              </ul>

            </div>
        </div>

        <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="updateEducation"
            @close="on_cancel"
            ref="saveButton"
          />

      </b-form>
    </div>
  </b-card>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import FacultyList from '@/view/pages/ml/education/FacultyList.vue';
import CompanyList from '@/view/pages/ml/education/CompanyList.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'EducationEditor',
  mixins: [ toasts ],
  components: {
    FacultyList,
    CompanyList,
    RightModalSaveAndCloseButtons
  },
  props: ['item'],
  emits: ['update:education', 'created'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
    validSection() {
      return this.local_education.company_id;
    },
    validType() {
      return this.local_education.type;
    }
  },
  mounted() {
    if (this.item) {
      this.local_education = { ...this.item }
    }
  },
  data() {
    return {
      local_education: {},
      errors: [],

      typeOptions: [
        { value: 'NONE', text: this.$t('PAGES.EDUCATIONS.TYPE_NONE') },
        { value: 'PROGRAM', text:  this.$t('PAGES.EDUCATIONS.TYPE_PROGRAM') },
        { value: 'COURSE', text:  this.$t('PAGES.EDUCATIONS.TYPE_COURSE') },
        { value: 'FREE_COURSE', text:  this.$t('PAGES.EDUCATIONS.TYPE_FREE_COURSE') },
      ],
      
    };
  },
  watch: {
    education: {
      deep: true,
      handler(val) {
        this.local_education = { ...val }
      }
    },
    'local_education.type'() { this.calcErrors() },
    'local_education.company_id'() { this.calcErrors() },
    'local_education.faculty_id'() { this.calcErrors() },
    'local_education.name'() { this.calcErrors() },
    'local_education.code'() { this.calcErrors() },

  },
  methods: {

    on_cancel() {
      this.$emit('cancel');
    },

    calcErrors() {
      this.errors = [];

      if (!this.local_education.type || this.local_education.type === 'NONE') {
        this.errors.push('type');
      }

      if (!this.local_education.company_id || this.local_education.company_id === '') {
        this.errors.push('company');
      }

      if (!this.local_education.faculty_id || this.local_education.faculty_id === '') {
        this.errors.push('faculty');
      }

      if (!this.local_education.code || this.local_education.code === '' || this.local_education.code.length < 4) {
        this.errors.push('code');
      }
    },


    updated_faculty_id(faculty_id) {
      this.local_education.faculty_id = faculty_id;
    },

    updated_company_id(company_id) {
      this.local_education.company_id = company_id;
    },

    async updateEducation(e) {
      if (e) {
        e.preventDefault();
      }


      if (!this.local_education.id) {
        try {
          const loader = this.$loading.show();

          const res = await axios.post('/education', this.local_education);
        
          loader && loader.hide();

          if (res.status === 201) {
            this.local_education = res.data;

            this.$emit('created', this.local_education);
          }
          else if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.ALREADY_EXISTS'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_CREATE'));
          }
          
          this.$refs['saveButton'].stop();
        }
        catch (err) {
          console.error('create education error', err);

          loader && loader.hide();

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_CREATE'));

          this.$refs['saveButton'].stop();
        }
        
      } else {

        await axios.put(`/education/${this.local_education.id}`, this.local_education);

        this.$emit('updated', this.local_education);
        this.$refs['saveButton'].stop();
      }
    },
    resetForm() {

    },

  }
};
</script>
